// @codekit-prepend "../bower_components/foundation-sites/dist/foundation.min.js"
// @codekit-prepend "parallaxMainImg.js"
// @codekit-prepend "vendor/TweenMax.min.js"
// @codekit-prepend "../bower_components/jquery-dotimeout/jquery.ba-dotimeout.min.js"
// @codekit-prepend "../bower_components/waypoints/lib/jquery.waypoints.min.js"
// @codekit-prepend "../bower_components/flickity/dist/flickity.pkgd.js"

require("../node_modules/foundation-sites/dist/js/foundation.min");
// require("./parallaxMainImg");
require("./vendor/TweenMax.min");
require("../bower_components/jquery-dotimeout/jquery.ba-dotimeout.min");
require("../bower_components/waypoints/lib/jquery.waypoints.min");
window.Rellax = require('rellax/rellax.min.js');

// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
// $(document).foundation();

/*=========================================
= Table of Contents
---------------------
1. Basic Vars
2. Masthead
    2.1 Darken Nav on Scroll
    2.2 Logo Animation
        2.2.1 Animation Function
        2.2.2 Logo on Hover
    2.3 Toggle Menu
3. Case Studies List
4. Get Measurements
5. Hype Scaler
6. Global Utilities
    6.1 Smooth Scrolling
    6.2 Load Video
7. Initiations
    7.1 On Load
    7.2 Change Copyright date
    7.3 Get Time of Day
    7.4 Javascript Appends
    7.5 Mobile device class
    7.6 Testimonials
    7.7 Rellax Parallax initiations
=========================================*/

/*=====================================
=            1. Basic Vars            =
=====================================*/
var scrollDist = 0;
var logoAnimStartDist = 250;
var tlLogo = new TimelineLite();
var brand = $('#brand'),
    $b  = $('#brand .letter-b'),
    $r  = $('#brand .letter-r'),
    $a  = $('#brand .letter-a'),
    $n  = $('#brand .letter-n'),
    $d  = $('#brand .letter-d'),
    $h  = $('#brand .letter-h'),
    $oo = $('#brand .letter-oo'),
    $t  = $('#brand .letter-t'),
    logoLetters = $('#brand .letter');
var w = window.innerWidth;
var h = window.innerHeight;
var isMobile = w > 640 ? false : true;
var isNavMobile = w > 800 ? false : true;

// var bp_medium =   
var headerHeight = 53;
var bored = $('#bored a');
// var hash;

/*=====  End of 1. Basic Vars  ======*/


/*=====================================
=            2. Masthead            =
=====================================*/

/*----------  2.1 Darken Nav on Scroll  ----------*/
function navScroll() {
    scrollDist = window.pageYOffset;
    if(scrollDist > 20) {
        $('.masthead').addClass('fixed');
    } else {
        $('.masthead').removeClass('fixed');
    }
    /* Logo animate distance */
    if(scrollDist > logoAnimStartDist) {
        tlLogo.timeScale(1).play();      
    } else {
        tlLogo.reverse();
    }
    
}

/*----------  2.2 Logo Animation  ----------*/

// 2.2.1 Animation Function
logoLetters.css("opacity", "1");
tlLogo.to($t, 0.4, {autoAlpha: 0})
    .to($h, 0.4, {autoAlpha: 0}, '-=0.25')
    .to($d, 0.4, {autoAlpha: 0}, '-=0.25')
    .to($n, 0.4, {autoAlpha: 0}, '-=0.25')
    .to($a, 0.4, {autoAlpha: 0}, '-=0.25')
    .to($r, 0.4, {autoAlpha: 0}, '-=0.25')
    .to($b, 0.4, {autoAlpha: 0}, '-=0.25')
    .to($oo, 1.5, {ease: Power2.easeInOut, x:-128}, 0);
tlLogo.stop();

// 2.2.2 Logo on Hover
brand.hover(function(){
    tlLogo.timeScale(2).reverse();
}, function(){
    if(scrollDist > logoAnimStartDist) {
        tlLogo.play();
    }
});

/*----------  2.3 Toggle Menu  ----------*/

var toggleCheckbox = $('#checkMenuToggle');
toggleCheckbox.removeAttr('checked');

$(document).ready(function(){
    var menuToggle = $('.top-bar .toggle-menu');
    var masthead = document.getElementById('masthead');
    var menuItems = $('#main-nav ul.menu li');
    var staggerMenuItems = new TimelineLite();
    var staggerDuration;
    var hamburgerClose = new TimelineLite();
    var topBun = $('.toggle-menu .bars .topBun');
    var burger = $('.toggle-menu .bars .burger');
    var bottomBun = $('.toggle-menu .bars .bottomBun');
    var wholeHamburger = $('.toggle-menu .bars');
    var toggleText = $('.toggle-menu .text');

    // Menu stagger
    staggerMenuItems.addLabel('beginning')
        .staggerFrom(menuItems, 0.4, {opacity: 0, y:-15, x:-15}, 0.08);
    
    staggerDuration = staggerMenuItems.duration();
    
    // Hambergur/Close Animation
    hamburgerClose.to(topBun, 0.4, {y:8, ease: Power1.easeIn})
        .to(bottomBun, 0.4, {y:-8, ease: Power1.easeIn}, '-=0.4')
        .to(burger, 0.3, {autoAlpha:0}, '-=0.2')
        .to(topBun, 0.5, {rotation:90, ease: Power2.easeOut}, '-=0.1')
        // .to(bottomBun, 0.5, {rotation:-45, ease: Power2.easeOut}, '-=0.5')
        .to(wholeHamburger, 1, {rotation: 45, ease:Power1.easeInOut}, '-=0.8')
        .to(toggleText, 0.6, {autoAlpha: 0}, '-=0.8');
    hamburgerClose.stop();

    function changeMenuStatus(){
        toggleCheckbox.prop("checked", !toggleCheckbox.prop("checked"));
        if(toggleCheckbox.prop('checked')) {
            $('html').addClass('nav-no-scroll');
            staggerMenuItems.timeScale(1).play();
            hamburgerClose.play();
        } else {
            $('html').removeClass('nav-no-scroll');
            staggerMenuItems.timeScale(3).reverse();
            hamburgerClose.timeScale(1.4).reverse();
        }
    }
    function properMediaMenu(){
        if(isNavMobile) {
            staggerMenuItems.seek('beginning');
            staggerMenuItems.stop();
        } else {
            staggerMenuItems.seek(staggerDuration);
        }
    }


    properMediaMenu();
    
    menuToggle.click(function(){
        changeMenuStatus();
    });
    $(window).resize(function() {
        properMediaMenu();
    });
    masthead.addEventListener('touchmove', function(e) {
            e.preventDefault();
    }, false);

});
/*=====  End of 2. Masthead  ======*/




/*============================================
=            3. Case Studies List            =
============================================*/

function caseStudiesDivide() {
    var overlay = $('#caseStudies .case-study-item .overlay');
    var yardstick = $('#caseStudies .case-study-item:first-child .overlay-details');
    var edgeDistance = yardstick.offset().left + yardstick.outerWidth();

    overlay.css('width', edgeDistance + 'px');
}
/*=====  End of 3. Case Studies List  ======*/

/*===========================================
=            4. Get Measurements            =
===========================================*/

function getMeasurements() {
    w = window.innerWidth;
    h = window.innerHeight;
    isMobile = w > 640 ? false : true;
    isNavMobile = w > 800 ? false : true;
    window.isRetina = isRetina();
    window.isIEEdge = (document.documentMode || /Edge/.test(navigator.userAgent)) ? true : false;
    if(!isMobile) {
        if($('#caseStudies').length > 0) {
            caseStudiesDivide();
        }
    }
    setTimeout(function(){
        $('.mainImg .bgLg').css('opacity', '1');
    }, 200);
}

function isRetina(){
    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
}

/*=====  End of 4. Get Measurements  ======*/


/*======================================
=            5. Hype Scaler            =
======================================*/
window.scaleSection = function(anim, alsoEnlarge) {
    var scaler = anim.parents('.scale-container');
    var containerw = scaler.innerWidth(); /* The width of the div, currently set to half of the window's width */
    var containerh = scaler.innerWidth(); /* The maximum possible height of the div; the recommend size for this is equal to or larger than the H of the element. */
    var anim_w = anim.width();
    var anim_h = anim.height();
    var scaleTo = containerw / anim_w;

    if(scaler.length > 0 ) {
        scaleTo = containerh / anim_h < scaleTo ? containerh / anim_h : scaleTo;
        if(!alsoEnlarge && scaleTo>1) {
        scaleTo = 1;
        }
        anim.css({
                "-moz-transform"    : "scale("+scaleTo+")",
                "-webkit-transform" : "scale("+scaleTo+")",
                "-ms-transform"     : "scale("+scaleTo+")",
                "-o-transform"      : "scale("+scaleTo+")",
                "transform"         : "scale("+scaleTo+")",
                'transform-origin'  : "top left"
        });
        scaler.css('max-height', anim_h * scaleTo + 'px' );
    }
}

function hypeSceneLoaded(hypeDocument, element, event) {
    // show the next scene
    $.doTimeout(200, function(){
        scaleSection($('#' + element.id), false);
    })
    // return false so it does not load the initial scene
    // return false;
}

if("HYPE_eventListeners" in window === false) {
window.HYPE_eventListeners = Array();
}
window.HYPE_eventListeners.push({"type":"HypeDocumentLoad", "callback":hypeSceneLoaded});



/*=====  End of 5. Hype Scaler  ======*/

/*===========================================
=            6. Global Utilities            =
===========================================*/

/*----------  6.1 Smooth Scrolling  ----------*/

$('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
            $('html,body').animate({
            scrollTop: target.offset().top-headerHeight
            }, 800);
            return false;
        }
    }
});

/*----------  6.2 Load Video  ----------*/
window.appendVideoSource = function(videoElem) {
    let videoSrc;
    if(window.isIEEdge) {
        videoSrc = videoElem.data('sourceie');
    } else if(window.isRetina) {
        videoSrc = videoElem.data('source2x');
    } else {
        videoSrc = videoElem.data('source');
    }
    if(videoSrc) {
        videoElem.attr('src', videoSrc);
        videoElem[0].load();
    }
    if(isIEEdge) {
        videoElem.parents('.surface-tablet').addClass('is-ie');
    }
}


/*=====  End of 6. Global Utilities  ======*/


/*======================================
=            7. Initiations            =
======================================*/
/*----------  7.1 On Load  ----------*/
$(document).ready(function(){
    navScroll();
    getMeasurements();
});
window.addEventListener('scroll', navScroll, false);
$(window).resize(function() {
    getMeasurements();
});

/*----------  7.2 Change Copyright date  ----------*/

$(function(){
        $('#year').html(new Date().getFullYear());
});

/*----------  7.3 Get Time of Day  ----------*/
var today = new Date();
var curHr = today.getHours();

function changeFooterGreeting(message) {
    $('#footer-greeting').text(message);
}

if (curHr < 12) {
    changeFooterGreeting('Good morning sunshine!');
} else if (curHr < 17) {
    changeFooterGreeting('Good afternoon friend!');
} else {
    changeFooterGreeting('Good evening partner!');
}

/*----------  7.4 Javascript Appends  ----------*/


$('head').append( $('<link rel="stylesheet" type="text/css" />').attr('href', 'https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css') );

/*----------  7.5 Mobile device class  ----------*/
if( /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent) ) {
    $('body').addClass('mobile-device');
    console.log('this is a mobile device');
}

/*----------  7.6 Testimonials  ----------*/
if($('#testimonials .carousel').length > 0) {
    $('#testimonials .carousel').flickity({
        wrapAround: true,
        autoPlay: 8000
    });
}

/*----------  7.7 Rellax Parallax initiations  ----------*/
window.parallax = new Rellax('.rellax', {
        'center' : true
    }
);
// timelineParallax = new Rellax('')



